import React from 'react';
import Page from '../components/layout/Page';
import Terms from '../components/layout/terms/Terms';

const TermsPage = () => (
  <Page title="Terms of Service">
    <Terms />
  </Page>
);

export default TermsPage;
